import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import {
  PAGE_HEADER_COLLAPSIBLE,
  PREVENT_DISMISS_ATTRIBUTE,
} from '@/hooks/useHeader/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import useMeasure from '@/hooks/useMeasure/useMeasure';
import usePathname from '@/hooks/usePathname/usePathname';
import { useSafeLayoutEffect } from '@/hooks/useSafeLayoutEffect/useSafeLayoutEffect';
import useScroll from '@/hooks/useScroll/useScroll';
import { animated, useSpring, useTrail } from '@react-spring/web';
import Clickable from '@uikit/components/Clickable/Clickable';
import Link from '@uikit/components/Link/Link';
import clsx from 'clsx';
import {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { headerLinkToClickableProps } from './helpers';
import styles from './PageHeader.module.scss';
import PageHeaderAction from './PageHeaderAction';
import PageHeaderActivator from './PageHeaderActivator';
import PageHeaderTitle from './PageHeaderTitle';
import { PageHeaderProps } from './types';

const PageHeader = forwardRef<HTMLElement, PageHeaderProps>((props, ref) => {
  const {
    addonsSlot,
    collapseBehavior = 'auto',
    contentWidth = 'fixed',
    isCollapsibleActive,
    isFullWidth = false,
    links = [],
    onCollapsibleToggle,
    overviewUrl,
    renderTitle,
    shouldBeInverted = false,
    shouldBeSticky = false,
    shouldHaveBackground = false,
    title,
  } = props;

  const [collapseLinks, setCollapseLinks] = useState(false);
  const [isTranslucent, setIsTranslucent] = useState(false);

  const isMobile = useIsMobile(BreakPoint.LG);
  const pageHeaderRef = useRef<HTMLElement>(null);
  const linksContainerRef = useRef<HTMLUListElement>(null);

  const { width: linksContainerWidth } = useMeasure(linksContainerRef, true);

  const pathname = usePathname();

  const hasActiveLink = links.some((link) => pathname === link.url);

  const dropdownLinksCount =
    collapseLinks && !!overviewUrl ? links.length + 1 : links.length;

  const dropdownAnimator = useSpring({
    delay: isCollapsibleActive ? 0 : 400,
    config: {
      mass: 1,
      tension: 200,
      friction: 16,
      clamp: !isCollapsibleActive,
    },
    height: isCollapsibleActive
      ? isMobile
        ? dropdownLinksCount * 49 + 15
        : dropdownLinksCount * 40 + 16
      : 0,
    opacity: isCollapsibleActive ? 1 : 0,
    pointerEvents: (isCollapsibleActive
      ? 'all'
      : 'none') as CSSProperties['pointerEvents'],
  });

  const dropdownLinksAnimator = useTrail(dropdownLinksCount, {
    config: {
      mass: 5,
      tension: 2000,
      friction: 100,
      clamp: !isCollapsibleActive,
    },
    delay: isCollapsibleActive ? 200 : 0,
    opacity: isCollapsibleActive ? 1 : 0,
    transform: isCollapsibleActive ? `translateX(0px)` : `translateX(-8px)`,
  });

  const toggleDropdown = () => onCollapsibleToggle(PAGE_HEADER_COLLAPSIBLE);

  const renderedTitle =
    renderTitle &&
    renderTitle({
      hasDropdown: collapseLinks,
      isDropdownActive: isCollapsibleActive,
      isInverted: shouldBeInverted,
      isTranslucent,
      shouldHaveBackground,
      toggleDropdown,
    });

  useSafeLayoutEffect(() => {
    if (isMobile && links.length > 0) {
      setCollapseLinks(true);
      return;
    }

    if (
      !isMobile &&
      links.length > 0 &&
      linksContainerRef.current &&
      linksContainerWidth > 0
    ) {
      setCollapseLinks(
        collapseBehavior === 'always' ||
          Math.ceil(linksContainerWidth) < linksContainerRef.current.scrollWidth
      );
    }
  }, [
    collapseBehavior,
    isMobile,
    links,
    linksContainerRef,
    linksContainerWidth,
  ]);

  useImperativeHandle<HTMLElement | null, HTMLElement | null>(
    ref,
    () => pageHeaderRef.current
  );

  useScroll(() => {
    if (shouldBeSticky && pageHeaderRef.current) {
      const { top } = pageHeaderRef.current.getBoundingClientRect();

      /**
       * Due to overscrolling and the collapse behavior of Safari on mobile,
       * the only reliable way is to check if the body has been scrolled past
       * the top position of the PageHeader. However, on desktop browsers,
       * the offsetTop value is 0 once the PageHeader is sticky, that's why
       * the old calculation is needed as a fallback.
       */

      setIsTranslucent(
        pageHeaderRef.current.offsetTop > 0
          ? window.scrollY >= Math.floor(pageHeaderRef.current.offsetTop)
          : Math.floor(Math.abs(top)) === 0
      );
    }
  });

  const content = (
    <nav
      className={clsx(styles.base, {
        [styles.hasBackdrop]: isCollapsibleActive && isMobile,
        [styles.isFluid]: contentWidth === 'fluid',
        [styles.isInverted]:
          shouldBeInverted && !isTranslucent && !shouldHaveBackground,
        [styles.isTranslucent]:
          isTranslucent && !isFullWidth && !shouldHaveBackground,
      })}
      ref={pageHeaderRef}
    >
      <div className={styles.dropdownActivator}>
        {renderTitle ? (
          renderedTitle
        ) : (
          <PageHeaderActivator
            isActive={isCollapsibleActive}
            isEnabled={collapseLinks}
            isInverted={
              shouldBeInverted && !isTranslucent && !shouldHaveBackground
            }
            onClick={toggleDropdown}
            title={title}
          >
            <PageHeaderTitle
              isInverted={
                shouldBeInverted && !isTranslucent && !shouldHaveBackground
              }
              isLinkEnabled={!collapseLinks}
              title={title ?? ''}
              url={overviewUrl}
            />
          </PageHeaderActivator>
        )}
      </div>

      <div className={styles.linksHost}>
        {links.length > 0 && (
          <>
            <ul
              aria-hidden="true"
              className={clsx(styles.links, styles.isMeasured)}
              ref={linksContainerRef}
              role="list"
            >
              {links.map((link) => {
                return (
                  <li className={styles.link} key={link.title}>
                    <span>{link.title}</span>
                  </li>
                );
              })}
            </ul>

            <animated.ul
              aria-hidden={collapseLinks && !isCollapsibleActive}
              className={clsx(styles.links, {
                [styles.isCollapsed]: collapseLinks,
              })}
              id="page-header-dropdown"
              style={collapseLinks ? dropdownAnimator : undefined}
              {...{
                [PREVENT_DISMISS_ATTRIBUTE]:
                  collapseLinks && isCollapsibleActive,
              }}
              role="list"
            >
              {collapseLinks && !!overviewUrl && (
                <animated.li
                  className={styles.linkContainer}
                  style={
                    dropdownLinksAnimator[
                      isCollapsibleActive ? 0 : dropdownLinksAnimator.length - 1
                    ]
                  }
                >
                  <Link className={styles.link} to={overviewUrl}>
                    Übersicht
                  </Link>
                </animated.li>
              )}

              {links.map((link, index) => {
                const hasOverviewLink = collapseLinks && !!overviewUrl;
                const openingTrailIndex = hasOverviewLink ? index + 1 : index;
                const closingTrailIndex = hasOverviewLink
                  ? dropdownLinksAnimator.length - 1 - (index + 1)
                  : dropdownLinksAnimator.length - 1 - index;

                const isActive = pathname === link.url;
                const props = headerLinkToClickableProps(link);

                return (
                  <animated.li
                    aria-current={
                      hasActiveLink ? (isActive ? 'page' : false) : undefined
                    }
                    className={styles.linkContainer}
                    style={
                      collapseLinks
                        ? dropdownLinksAnimator[
                            isCollapsibleActive
                              ? openingTrailIndex
                              : closingTrailIndex
                          ]
                        : undefined
                    }
                    key={link.title}
                  >
                    <Clickable
                      className={clsx(styles.link, {
                        [styles.isActive]: isActive,
                      })}
                      {...props}
                    >
                      <span>{link.title}</span>
                    </Clickable>
                  </animated.li>
                );
              })}
            </animated.ul>
          </>
        )}
      </div>

      {addonsSlot && <div className={styles.slotContainer}>{addonsSlot}</div>}
    </nav>
  );

  return isFullWidth ? (
    <div
      className={clsx(styles.fullWidthContainer, {
        [styles.isInverted]:
          shouldBeInverted && !isTranslucent && !shouldHaveBackground,
        [styles.isTranslucent]: isTranslucent && !shouldHaveBackground,
      })}
    >
      {content}
    </div>
  ) : (
    content
  );
});

PageHeader.displayName = 'PageHeader';

export default Object.assign(PageHeader, {
  Action: PageHeaderAction,
  Activator: PageHeaderActivator,
  Title: PageHeaderTitle,
});
